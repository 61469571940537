import React, { useEffect } from "react";
import { createContext, useState } from "react";

const LOCAL_STORAGE_WORKSPACE_ID_KEY = "workspace_id";
//create a context, with createContext api
export const WorkspaceContext = createContext<{
  workspace: string | undefined;
  setWorkspace: (workspaceId: string | undefined) => void;
}>({
  workspace: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setWorkspace: () => {},
});

interface Props {
  children: React.ReactNode;
}

const WorkspaceProvider = ({ children }: Props) => {
  const [workspace, setWorkspace] = useState<string | undefined>();

  useEffect(() => {
    // load last active workspace from local storage
    if (!workspace) {
      const storedWorkspaceId = localStorage.getItem(
        LOCAL_STORAGE_WORKSPACE_ID_KEY
      );
      if (storedWorkspaceId) {
        setWorkspace(storedWorkspaceId);
      }
    }
  }, [workspace]);

  const setWorkspacePersistently = (workspaceId: string | undefined) => {
    if (workspaceId) {
      localStorage.setItem(LOCAL_STORAGE_WORKSPACE_ID_KEY, workspaceId);
    }
    return setWorkspace(workspaceId);
  };

  return (
    <WorkspaceContext.Provider
      value={{
        workspace,
        setWorkspace: setWorkspacePersistently,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
