import "react-toastify/dist/ReactToastify.css";
import "public/css/global.css";
import "react-loading-skeleton/dist/skeleton.css";

import { SessionProvider } from "next-auth/react";
import { AppContext, AppInitialProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React, { ReactElement } from "react";
import { toast, ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";
import WorkspaceProvider from "components/providers/WorkspaceProvider";
import dynamic from "next/dynamic";

const MoonPayProvider = dynamic(
  () => import("@moonpay/moonpay-react").then(mod => mod.MoonPayProvider),
  { ssr: false }
);

function App({
  Component,
  pageProps,
}: AppContext & AppInitialProps): ReactElement {
  if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V2) {
    throw Error("Env variable NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V2 not set.");
  }
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <link rel="icon" type="images/png" href="/images/logo_symbol.png" />
        <link
          rel="shortcut icon"
          href="/images/favicon.ico"
          type="image/x-icon"
        />
        <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/images/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/images/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/images/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/images/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/images/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/images/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon-180x180.png"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Blockfrost.io - Cardano API</title>
        <meta name="title" content="Blockfrost.io - Cardano API" />
        <meta
          name="description"
          content="We provide an instant and scalable Cardano API for free."
        />
        <meta
          name="keywords"
          content="Cardano, IPFS, API, Cardano API, SDK, Developers, Ethereum killer, Proof of Stake, NFT, ADA, Lovelace, Shelley, Goguen, Byron, Blockchain, Typescript, Going for #1"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blockfrost.io/" />
        <meta property="og:title" content="Blockfrost.io - Cardano API" />
        <meta
          property="og:description"
          content="We provide an instant and scalable Cardano API for free."
        />
        <meta
          property="og:image"
          content="https://blockfrost.io/images/og.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blockfrost.io/" />
        <meta property="twitter:title" content="Blockfrost.io - Cardano API" />
        <meta
          property="twitter:description"
          content="We provide an instant and scalable Cardano API for free."
        />
        <meta
          property="twitter:image"
          content="https://blockfrost.io/images/og.png"
        />
      </Head>
      <Script
        async
        data-domain="blockfrost.io"
        src="https://plausible.io/js/plausible.js"
      />
      <Script
        defer
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=a0ff99fa-0608-4825-b70d-16a2cd918a39"
        strategy="lazyOnload"
      />

      <SessionProvider session={pageProps.session}>
        <MoonPayProvider apiKey={process.env.MOONPAY_API_KEY ?? ""}>
          <WorkspaceProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={true}
              closeOnClick
              pauseOnFocusLoss
              pauseOnHover
              style={{ top: "60px" }}
            />
            <SWRConfig
              value={{
                onError: () => {
                  if (pageProps.session) {
                    // Workaround for errors on sign out (SWR will fetch various endpoints that will return 403)
                    // Shows error message only if user is logged in
                    toast.error("Cannot connect to a backend server.", {
                      toastId: "swr-fetch-error",
                      autoClose: 10000,
                    });
                  }
                },
              }}
            >
              <Component {...pageProps} />
            </SWRConfig>
          </WorkspaceProvider>
        </MoonPayProvider>
      </SessionProvider>
    </>
  );
}

export default App;
